

































































import { api } from "@/api/api";
import { ApiGetMemberOrganizationDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import {
  validateClientAccountNumber,
  validateIsEmail,
  validateIsNorwegianPhoneNumber,
  validateIsOrganizationNumber,
  validateNotEmpty,
} from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, PropType, Ref, ref } from "@vue/composition-api";
import Vue from "vue";
export default defineComponent({
  name: "MemberOrganizationModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      validator: (value: string) => (Object.values(ModalType) as string[]).includes(value),
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    mainOffice: {
      type: Object as PropType<ApiGetMemberOrganizationDto | null>,
      default: null,
    },
    isRegionOfficeModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "update"],
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const singleMainOffice: Ref<ApiGetMemberOrganizationDto> = ref(
      props.mainOffice ? deepCloneObject(props.mainOffice) : getOfficeInitialData(store.state.hoc.usePreFilledValues)
    );

    const updateMemberOrganizationStatus = async () => {
      if (singleMainOffice.value.isActive) {
        await api.organization.activateMemberOrganizationsAsync(singleMainOffice.value.id);
      } else {
        await api.organization.deactivateMemberOrganizationsAsync(singleMainOffice.value.id);
      }
    };

    const onSubmit = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const isValid = (refs.form as Vue & { validate: () => boolean }).validate();
        if (!isValid || !singleMainOffice.value) {
          return;
        }
        const data = singleMainOffice.value;

        // If region office inherits org no from parent, BE required that org no is set to null
        if (props.isRegionOfficeModal && singleMainOffice.value.inheritOrgNoFromParent) {
          data.orgNo = null;
        }

        if (props.modalType === ModalType.Add) {
          await api.organization.postMemberOrganizationsAsync(data);
          openNotification(store, NotificationItemType.Success, "Medlemsorganisasjonen er lagt til kurset");
        } else if (props.modalType === ModalType.Edit) {
          if (!singleMainOffice.value.id) {
            return;
          }

          await api.organization.updateMemberOrganizationsAsync(singleMainOffice.value.id, data);

          openNotification(store, NotificationItemType.Success, "Medlemsorganisasjon er oppdatert");
        }
        emit("close");
        emit("update");
      });
    };

    if (props.modalType === ModalType.Add) {
      singleMainOffice.value.parentId = +route.params.mainOfficeId;
    }

    return {
      singleMainOffice,
      updateMemberOrganizationStatus,
      onSubmit,
      validateNotEmpty,
      validateIsNorwegianPhoneNumber,
      validateIsOrganizationNumber,
      validateIsEmail,
      validateClientAccountNumber,
    };
  },
});

const getOfficeInitialData = (usePreFilledValues: boolean): ApiGetMemberOrganizationDto => {
  if (usePreFilledValues) {
    return {
      email: "test.test@test.test",
      id: 0,
      inserted: "",
      insertedBy: 0,
      isActive: true,
      isWebVisible: true,
      name: "Test [Autogenerated]",
      orgNo: "123456789",
      parentId: null,
      phone: "12345678",
      ssbId: "1",
      updated: "",
      updatedBy: 0,
      url: "",
      clientAccountNumber: 1111,
      inheritOrgNoFromParent: false,
    };
  }
  return {
    email: "",
    id: 0,
    inserted: "",
    insertedBy: 0,
    isActive: true,
    isWebVisible: true,
    name: "",
    orgNo: "",
    parentId: null,
    phone: "",
    ssbId: "",
    updated: "",
    updatedBy: 0,
    url: "",
    inheritOrgNoFromParent: false,
  };
};
